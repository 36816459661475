import React, { useEffect, useMemo, useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const setFormValues = hooks.useFormChange()
  const [enableCustomPayment, setEnableCustomPayment] = hooks.useFormState('enableCustomPayment')
  const [direct_access, payment_types, name] = hooks.useFieldValues(['direct_access', 'payment_types', 'name', 'formula'])

  const [company_id] = hooks.useFormState('company_id')
  const [company] = hooks.useModel('company', [company_id], { single: true })
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')


  const getTitleFormatted = useCallback((cmpy_name, cntr_name, isManaging) => !!isManaging && `${cmpy_name} - ${cntr_name}`, [])
  const titleFormatted = useMemo(() => getTitleFormatted(_.get(company, 'name'), name, isManagingRecord), [_.get(company, 'name'), name, isManagingRecord])

  const verifyClientId = useCallback(hooks.useRecordExist('companycontract', 'client_id'), [])

  const handleClickEnableCustomPayment = useCallback(() => {
    setEnableCustomPayment(!enableCustomPayment)
  }, [enableCustomPayment, setEnableCustomPayment])

  const onAddressResolve = useCallback(Callbacks.handleOnAddressResolve(setFormValues), [setFormValues])

  useEffect(() => {
    _.isEmpty(payment_types) && !!enableCustomPayment && setEnableCustomPayment(false)
  }, [_.join(payment_types, ',')])

  const handleDirectAccessChange = useCallback(value => {
    !!value && setFormValues([{ field: 'display_list', value: false }])
  }, [])
  const handleDisplayListChange = useCallback(value => {
    !!value && setFormValues([{ field: 'direct_access', value: false }])
  }, [])

  const mergedProps = {
    enableCustomPayment,
    payment_types,
    isManagingRecord,
    isReadOnly,
    direct_access,
    titleFormatted,
    handleDirectAccessChange,
    handleDisplayListChange,
    handleClickEnableCustomPayment,
    onAddressResolve,
    verifyClientId,
  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
