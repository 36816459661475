import React from 'react'

const withContainer = Component => ({ job_ids }) => {
  const mergedProps = {
    job_ids,
  }
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
