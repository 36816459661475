import React from 'react'
import I18n from '@front/volcanion/utils/translation'

import { Box, Link, Typography, Button, Badge } from '@mui/material'

const DriverComponent = ({ user_client_id }) =>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Typography color='white' variant='h5' sx={{ ml: 1 }}> {user_client_id}</Typography>
    <Typography variant='h5' color='white'>,</Typography>
  </Box >

const VehicleComponent = ({ plaque, vehicle_id, group_id, license_client_id, vehicle_status }) =>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Link
      color='white'
      variant='h5'
      target='_blank'
      href={`/adherent/${group_id}/vehicle/${vehicle_id}`}
      underline={!plaque ? 'none' : 'always'}
    >
      {plaque || I18n.t('driver.not_paired')}
    </Link>
    {!!license_client_id || !!vehicle_status && <Typography variant='h5' color='white'>,</Typography>}
  </Box>

const LicenseComponent = ({ license_client_id, group_id, license_id }) =>
  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }} >
    <Typography color='white' variant='h5' sx={{ mr: 1 }}> {_.toLower(I18n.t('contract.label', { count: 1 }))} </Typography>
    <Link color='white' variant='h5' target='_blank' href={`/adherent/${group_id}/license/${license_id}`}> {license_client_id} </Link>
  </Box>

const StatusComponent = ({ vehicle_status, openDialog, orders }) => <>
  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
    <Typography variant='h5' color='white'> {_.toLower(I18n.t('status.label', { count: 1 }))} :</Typography>
    <Box hidden={!vehicle_status} sx={{ ml: 1 }}>
      {vehicle_status === 'in_use'
        ? <Badge badgeContent={orders?.length} color="error">
          <Button onClick={() => openDialog('resume_active_order')} variant='text'>
            <Typography variant='h5' color='white' sx={{ textTransform: 'none' }}> {_.toLower(I18n.t(`vehicle.status.${vehicle_status}`))} </Typography>
          </Button>
        </Badge>
        : <Typography variant='h5' color='white'> {_.toLower(I18n.t(`vehicle.status.${vehicle_status}`))} </Typography>
      }
    </Box>
  </Box>
</>

const TitleComponent = ({
  isManagingRecord,
  user_client_id,
  vehicle_status,
  openDialog,
  orders,
  license_id,
  plaque,
  vehicle_id,
  group_id,
  license_client_id
}) => <>
    {!!isManagingRecord &&
      <Box sx={{ display: 'flex' }}>
        <DriverComponent
          user_client_id={user_client_id}
        />
        <VehicleComponent
          plaque={plaque}
          vehicle_id={vehicle_id}
          group_id={group_id}
          license_client_id={license_client_id}
          vehicle_status={vehicle_status}
        />
        {!!license_client_id && <LicenseComponent
          license_client_id={license_client_id}
          group_id={group_id}
          license_id={license_id}
        />}
        {!!vehicle_status && <StatusComponent
          vehicle_status={vehicle_status}
          openDialog={openDialog}
          orders={orders}
        />}
      </Box>
    }
  </>


export default TitleComponent
