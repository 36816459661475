import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import { ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ record_id, group_id }) => {
  const { openNotification } = hooks.useNotification()
  const [, { openDialog }] = hooks.useDialogs()
  const [driverCategories] = hooks.useModelSearch('categoryoption', 'get', { initial_filter: { type: 'driver' } })
  const CODE_INTERNATIONAL = hooks.useRelayConstant('CODE_INTERNATIONAL')

  const [suspended] = hooks.useGlobalFormState('driver_form', 'suspended')
  const [jobs] = hooks.useModelSearch('job', 'get', {
    initial_filter: {},
    forced_filter: {
      driver: record_id,
      status: { '!': ['complete', 'canceled'] },
      deferTo: {
        '<=': suspended?.suspendedUntil,
        '>=': suspended?.suspendedSince,
      },
    },
    watchers: [record_id, suspended?.suspendedUntil, suspended?.suspendedSince],
  })
  const job_ids = _.map(jobs, 'job_id')

  const formProps = {
    name: 'driver_form',
    model_name: 'user',
    record_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(driverCategories), [driverCategories]),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(CODE_INTERNATIONAL), [CODE_INTERNATIONAL]),
    formToOptions: useCallback(Callbacks.formToOptionsHandler(), []),
    populate: ['vehicle.info', 'score', 'driverinfo', 'info', 'auth', 'options', 'payment_types', 'heatmap_blacklist'],
    initialState: { isReadOnly: !!record_id, group_id },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(openNotification, openDialog), [openNotification, openDialog]),
    beforeSubmit: useCallback(Callbacks.beforeSubmitHanbdler(openDialog, job_ids), [openDialog, job_ids]),
    submitChangesOnly: false
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
